import Customers from '@/components/widgets/customers'
import DatePicker from 'vue2-datepicker'
import CustomerCreate from "@/components/customer/customer-create";

export default {
  name: 'Bill-Customer',
  components: {
    Customers,
    DatePicker,
    CustomerCreate
  },
  props: {
    customerItem: {
      type: Object
    }
  },
  data() {
    return {
      isCreateCustomerOpened: false,
      customer_opened: false,
      formData: {
        customer_id: '',
        bill_customer_code: '',
        bill_no: '',
        bill_customer_name: '',
        bill_customer_addr: '',
        bill_customer_phone_number: '',
        bill_customer_shipping_address: '',
        bill_shipping_method: null,
      }
    }
  },
  watch: {
    'customerItem': {
      handler(newValue, oldValue) {
        this.formData = newValue
      },
      deep: true
    },
    'formData': {
      handler(newValue, oldValue) {
        this.$emit('customerData', { 
          customer: newValue
        })
      },
      deep: true
    }
  },
  methods: {
    /** เปิด dialog ค้นหาข้อมูลลูกค้า */
    onOpenCustomerHandler() {
      this.customer_opened = !this.customer_opened
    },
    /** เพิ่มข้อมูลลูกค้า */
    onInitCustomerSettings(e) {
      this.customer_opened = false
      if (e.status) {
        const data = e.data
        this.formData.customer_id = data.id
        this.formData.bill_customer_code = data.code?? data.code
        this.formData.bill_no = data.bill_no?? data.bill_no
        this.formData.bill_customer_name = data.name?? data.name
        this.formData.bill_customer_addr = data.address?? data.address
        this.formData.bill_customer_phone_number = data.phone_number?? data.phone_number
        this.formData.bill_customer_shipping_address = data.address?? data.address

        this.formData.deposit_document_id = null
        this.formData.deposit_document_no = ""
      }
    },
    handleCreateEvent(e) {
      if (e.status) {
        const data = e.data
        this.formData.customer_id = data.id
        this.formData.bill_customer_code = data.code?? data.code
        this.formData.bill_no = data.bill_no?? data.bill_no
        this.formData.bill_customer_name = data.name?? data.name
        this.formData.bill_customer_addr = data.address?? data.address
        this.formData.bill_customer_phone_number = data.phone_number?? data.phone_number
        this.formData.bill_customer_shipping_address = data.address?? data.address
      }
    },
    onclear() {
      this.formData.customer_id = ''
      this.formData.bill_no = ''
      this.formData.bill_customer_code = ''
      this.formData.bill_customer_name = ''
      this.formData.bill_customer_addr = ''
      this.formData.bill_customer_phone_number = ''
      this.formData.bill_customer_shipping_address = ''
    }
  }
}