import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
import { STOCK_COINS_STATUS } from '../../../mixins/enum'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      stock_coins_status: STOCK_COINS_STATUS,
      fields: [
        { 
          key: 'name', 
          label: 'ชื่อประเภทการตัด',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2',
          sortable: true , 
        },
        { 
          key: 'description', 
          label: 'คำอธิบาย',  
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 text-left',
          sortable: true
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [
        {
          uuid: "123456a",
          description: "Q1",
          name: "ตัดตรง",
        },
        {
          uuid: "123456b",
          description: "Q2",
          name: "ครอบข้าง 457",
        },
      ]
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onClose () {
      this.$emit('onCoinCuttingListCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.$emit('onCoinCuttingListCloseHandler', {
        status: true,
        data: item
      })
    },
    async onSearchHandler (currentPage) {
      this.busy = true
      setTimeout(() => {
        this.busy = false
      }, 500)
    }
  }
}
