import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { STOCK_COINS_STATUS } from '../../../mixins/enum'
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      totalItem: 0,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      stock_coins_status: STOCK_COINS_STATUS,
      fields: [
        {
          key: "code",
          label: "รหัส",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "type",
          label: "ประเภท",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "name",
          label: "ชื่อสินค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "qualtity",
          label: "จำนวน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "unit_name",
          label: "หน่วยนับ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "description",
          label: "รายละเอียด",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "cost",
          label: "ต้นทุน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาขาย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-0.5',
          class: 'text-right' 
        }
      ],
      items: [

      ]
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onGoodsListCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.$emit('onGoodsListCloseHandler', {
        status: true,
        data: item
      })
    },
    async onSearchHandler (currentPage) {
      // this.loading = true
      // this.items = []
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      const page = currentPage ? currentPage : 1
      const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/miscellaneous?page=${page}&perPage=8&keyword=${this.textInput}&isBranchStock=true`)
      setTimeout(() => {
        // this.loading = false        
        this.items = result.data.data
        this.totalItem = result.data.total
        this.apiStatus = 'ไม่พบข้อมูล.'
        this.busy = false
        if (result) {
          this.currentData = result.data
          this.items = result.data.data
        } else {
          this.apiStatus = 'ไม่พบข้อมูล'
          this.onExceptionHandler()
        }
      }, 500)
    }
  }
}
