import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Paginate
  },
  data() {
    return {
      busy: false,
      textInput: '',
      answer: 'ค้นหาจากเลขที่เอกสาร',
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-xs-1/2 py-1.5',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-1.5',
          sortable: true , 
        },
        { 
          key: 'deposit', 
          label: 'เงินมัดจำ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-1.5',
          sortable: true
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-1.5',
          class: 'text-right' 
        }
      ],
      items: [
        {
          uuid: '123456a',
          doc_no: 'SV-2651651511',
          doc_date: new Date(),
          deposit: 1000
        }
      ]
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  methods: {
    onClose () {
      this.$emit('onDepositCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.$emit('onDepositCloseHandler', {
        status: true,
        data: item
      })
    },
    async onSearchHandler (currentPage) {
      this.busy = true
      setTimeout(() => {
        this.busy = false
      }, 500)
    }
  }
}
