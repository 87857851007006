import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required, numeric } from "vuelidate/lib/validators"
import Loading from "@/components/widgets/Loading.vue"
import Vue from 'vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      inputType: 'file',
      fileError: '',
      file: null,
      customerTypeOption: [],
      backupFormData: {
        name: "",
        company_name: "",
        address: "",
        contact: "",
        id: "",
        uuid: ""
      },
      form: {
        _id: null,
        customer_type: null,
        image: null,
        tax_no: null,
        company: null,
        code: null,
        name: null,
        phone_number: null,
        email: null,
        facebook: null,
        line: null,
        addr: null,
        description: null
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      tax_no: { numeric },
      customer_type: { },
      company: { required },
      code: { required },
      name: { required },
      phone_number: { required },
      addr: { required }
    },
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.getCustomerTypes()
        this.showFileInput = true
        this.drawerOpened = n
        if (this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  methods: {
    resetForm () {
      this.form.image = null
      this.form.customer_type = null
      this.form.code = null
      this.form.tax_no = null
      this.form.name = null
      this.form.company = null
      this.form.phone_number = null
      this.form.email = null
      this.form.facebook = null
      this.form.line = null
      this.form.addr = null
      this.form.description = null

      this.$nextTick(() => {
        this.$v.$reset();
      })

      this.onResetFileInput()
    },
    formBuilder(obj) {
      this.form.customer_type = obj.data?.customer_type ?? null
      this.form._id = obj.data?.uuid ?? ''
      this.form.tax_no = obj.data?.tax_id ?? ''
      this.form.company = obj.data?.company_name ?? ''
      this.form.code = obj.data?.code ?? ''
      this.form.name = obj.data?.name ?? ''
      this.form.phone_number = obj.data?.phone_number ?? ''
      this.form.email = obj.data?.email ?? ''
      this.form.facebook = obj.data?.facebook ?? ''
      this.form.line = obj.data?.line ?? ''
      this.form.addr = obj.data?.address ?? ''
      this.form.description = obj.data?.description ?? ''

      // this.$v.form.$touch()
    },
    onCloseDrawer(event, data) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true,
        data: this.backupFormData
      })
    },
    onFileHandler(e) {
      this.$refs.fileInput.click()
    },
    onResetFileInput() {
      this.inputType = 'text'      
      this.$nextTick(() => {
        this.fileError = ''
        this.file = null
        this.inputType = 'file'    
      })
    },
    onFileChange(e) {
      if (e.target.files.length > 0) { 
        this.fileError = ''
        const selectedFile = e.target.files[0]
        // File type validation (accept only image files)
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedFileTypes.includes(selectedFile.type)) {
          this.fileError = 'ไฟล์รูปภาพไม่ถูกต้อง. กรุณาเลิอกไฟล์รูปภาพ.'
          return;
        } 
        
        this.onFIletoBase64(selectedFile).then(file => {
          this.file = file
          this.form.image = file
        }).catch(error => {
          this.onExceptionHandler(error.message)
        })
      }      
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onInitData() {
      try {
        const param = {
          _id: this.id
        }
        const result = await this.$store.dispatch('Customers/get', param, { root: true })
        if (result.status === 200) {
          this.formBuilder(result)
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async getCustomerTypes() {
      const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/customer-types`)
      this.customerTypeOption = result.data.data
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        if (this.form.image) {
          formData.append('image', this.form.image)
        }

        formData.append('code', this.form.code?? '')
        formData.append('name', this.form.name?? '')
        formData.append('customer_type', this.form.customer_type?? '')
        formData.append('phone_number', this.form.phone_number?? '')
        formData.append('email', this.form.email?? '')
        formData.append('line', this.form.line?? '')
        formData.append('facebook', this.form.facebook?? '')
        formData.append('address', this.form.addr?? '')
        formData.append('company_name', this.form.company?? '')
        formData.append('tax_id', this.form.tax_no?? '')
        formData.append('description', this.form.description?? '')

        let result = {}
        if (this.mode === 'create') {
          result = await this.$store.dispatch('Customers/create', formData, { root: true })
        } else {
          const body = {
            _id: this.id,
            formData: formData
          }
          result = await this.$store.dispatch('Customers/update', body, { root: true })
        }

        this.loading = false    
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(res => {
              this.backupFormData = result.data.data
              this.onCloseDrawer()
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}