import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default { 
  props: {
    width: {
      type: String,
      default: 'w-50 m-auto'
    },
    show: {
      type: Boolean,
      default: false
    },
    quotation: {
      type: String,
      default: ''
    },
  },
  components: {
    Paginate
  },
  data() {
    return {
      apiStatus: 'ไม่พบข้อมูล',
      busy: false,
      textInput: '',
      totalItem: 0,
      perPage: 20,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      fields: [
        { 
          key: 'document_no', 
          label: 'เลขที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'paid_at', 
          label: 'วันที่จ่าย',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'customer.name', 
          label: 'ชื่อลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'customer.company_name', 
          label: 'บริษัท',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'amount', 
          label: 'ยอดมัดจำ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true, 
          class: 'text-right' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-right font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2 py-1.5',
          class: 'text-right' 
        }
      ],
      items: []
     }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  watch: {
    show: function (newValue, oldValue) {
      if (newValue) {
        this.onSearchHandler(this.currentPage) 
      }
    }
  },
  methods: {
    onClose () {
      this.textInput = ''
      this.$emit('onDocumentCloseHandler', {
        status: false
      })
    },
    onSelected(item) {
      this.textInput = ''
      this.$emit('onDocumentCloseHandler', {
        status: true,
        data: item
      })
    },
    async onSearchHandler (currentPage) {
      // this.loading = true
      // this.items = []
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      const page = currentPage ? currentPage : 1
      const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/depositsForBill?page=${page}&perPage=8&keyword=${this.textInput}&quotation=${this.quotation}`)
      setTimeout(() => {
        // this.loading = false
        this.currentData = result.data;
        this.items = result.data.data
        this.totalItem = result.data.total
        this.apiStatus = 'ไม่พบข้อมูล.'
        this.busy = false
        if (result) {
          this.items = result.data.data
        } else {
          this.apiStatus = 'ไม่พบข้อมูล'
          this.onExceptionHandler()
        }
      }, 500)
    }
  }
}
